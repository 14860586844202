.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-tabview-panels {
  padding-bottom: 0 !important;
}

.p-datatable-header {
  padding-bottom: 0 !important;
}

#replication-panel > div > div.p-tabview-panels{
  background-color: transparent !important;
}

#notifications-panel > div > div.p-tabview-panels{
  background-color: transparent !important;
}

#schedule-select-button > div {
  padding-top: 5px;
  padding-bottom: 5px;
}

ul.p-tabview-nav {
  display: flex;
  justify-content: center;
}

.spinner_overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.2;
    z-index: 1000;
}

.small-button {
  font-size: 0.2em !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  max-width: 10em;
  max-height: 10em;
}

.small-button-2 {
  font-size: 0.6em !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  /* max-width: 13em; */
}

.plan-table-row {
  border-style: outset none none none;
  padding: 10px;
  font-size: 15px;
  margin: 0;
  overflow-x: hidden;
}

/* execution status */
.status{border-radius:2px;padding:.25em .5rem;text-transform:uppercase;font-weight:700;font-size:11px;letter-spacing:.3px;}
.status.created{background-color:#e6e6e6;color:#040504;}
.status.queued{background-color:#e6e6e6;color:#040504;}
.status.started{background-color:#c8c9e6;color:#252b60;}
.status.running{background-color:#c8c9e6;color:#252b60;}
.status.success{background-color:#c8e6c9;color:#256029;}
.status.terminated{background-color:#ffcdd2;color:#c63737;}
.status.interrupted{background-color:#ffcdd2;color:#c63737;}
.status.timed-out{background-color:#ffcdd2;color:#c63737;}
.status.error{background-color:#ffcdd2;color:#c63737;}
.status.skipped{background-color:#e6e6e6;color:#040504;}
.status.stalled{background-color:#ffcdd2;color:#c63737;}

/* job mode */
.mode{border-radius:2px;padding:.25em .5rem;text-transform:uppercase;font-weight:700;font-size:9px;letter-spacing:.3px;}
.mode.full-refresh{background-color:#b6c1ff;color:#040504;}
.mode.incremental{background-color:#cbffcc;color:#040504;}
.mode.full-refresh-incremental{background-color:#e6e6e6;color:#040504;}
.mode.truncate{background-color:#eec7ff;color:#040504;}
.mode.append{background-color:#e6e6e6;color:#040504;}
.mode.snapshot{background-color:#ffedc4;color:#040504;}

/* account status */
.status.current{background-color:#c8e6c9;color:#256029;}
.status.balance{background-color:#ffcdd2;color:#c63737;}

/* worker status */
.status.online{background-color:#c8e6c9;color:#256029;}
.status.starting{background-color:#c8e6c9;color:#256029;}
.status.offline{background-color:#ffcdd2;color:#c63737;}
.status.timed_out{background-color:#ffcdd2;color:#c63737;}
.status.paused{background-color:#c8c9e6;color:#252b60;}
.status.provisioning{background-color:#c8c9e6;color:#252b60;}

/* history rows */
div.history-table > div.p-datatable-wrapper > table > tbody > tr > td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.code-block {
  width: 100%;
  background: #F0F0F0;
  font-family: monospace;
  padding: 20px;
  border-radius: 10px;
  white-space: normal;
  overflow-x: scroll;
}

#workspace-chooser > .p-dialog-content {
  padding-bottom: 5px;
}

div.p-fileupload-content {
  padding: 15px !important;
}

#job-sql-toggle > div.p-button {
  width: 50%;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.column-wrap {
  white-space: pre-wrap;
  word-break: break-word;
}

#key-manager-dialog_content > div > div > div > table > tbody > tr > td {
  padding-top: 5px;
  padding-bottom: 5px;
}

#key-manager-dialog_content > div > div > div > table > tbody > tr > td > span > div > span.p-dropdown-label {
  font-size: 10px !important;
}
